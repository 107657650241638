<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.liveOffer') }}</h5>
                <Toast />
                <div class="p-grid">
                    <div class="p-col-12 p-md-2">
                        <Calendar ref="startDateCalendar" style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="startDateModel"></Calendar>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <Calendar style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="endDateModel"></Calendar>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <Dropdown style="width: 100%" v-model="sportsModel" :options="sports" optionLabel="name" :placeholder="$t('dropdown.placeholder.sport')" :filter="!this.isMobile()" />
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Dropdown id="inOfferDropDown" style="width: 100%" v-model="inOfferModel" :options="inOfferOptions" optionLabel="Name" :placeholder="$t('dropdown.placeholder.inOffer')" />
                            <label for="inOfferDropDown">{{ $t('dropdown.placeholder.inOffer') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Dropdown id="statusDropDown" style="width: 100%" v-model="statusModel" :options="statusOptions" optionLabel="Name" :placeholder="$t('dropdown.placeholder.status')" />
                            <label for="statusDropDown">{{ $t('dropdown.placeholder.fixtureStatus') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2 p-text-right"><Button :label="$t('buttons.getFixtures')" class="p-button-raised p-button-success" @click="readItems()" /></div>
                </div>
                <div class="p-grid">
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <InputText style="width: 100%" type="text" v-model="offerNumber" />
                            <label>{{ $t('search.byOfferNumber') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <InputText style="width: 100%" type="text" v-model="searchName" />
                            <label>{{ $t('search.byName') }}</label>
                        </span>
                    </div>
                </div>
                <transition-group name="p-message" tag="div" v-show="showNoEvents" class="p-col-12 p-mb-2">
                    <Message :key="errorKeyCount" :severity="'info'">{{ $t('sportsbets.alerts.noEventsToImport') }}</Message>
                </transition-group>
                <DataTable
                    v-show="showFixturesTable"
                    :value="filteredData"
                    :paginator="true"
                    :rows="perPage"
                    :loading="loading"
                    ref="datatable"
                    responsiveLayout="scroll"
                    :paginatorBottom="true"
                    v-model:expandedRows="expandedRows"
                    @page="onPage($event)"
                >
                    <template #header> </template>
                    <Column
                        ><template #body="slotProps">
                            <span class="indexColumn">{{ (this.crtPage - 1) * this.perPage + slotProps.index + 1 }}</span>
                        </template>
                    </Column>
                    <Column :expander="true" headerStyle="width: 3rem" />
                    <Column :header="$t('sportsbets.inOffer')">
                        <template #body="{ data }">
                            <Button :icon="'pi pi-' + inOfferIcon(data.inOffer)" :class="'p-button-rounded p-button-' + inOfferColor(data.inOffer) + ' p-mr-2'" />
                        </template>
                        ></Column
                    >
                    <Column field="fixtureId" :header="$t('sportsbets.fixtureId')" :sortable="true"></Column>
                    <Column field="offerNumber" :header="$t('sportsbets.offerNo')" :sortable="true">
                        <template #body="{ data }"> {{ data.offerNumber != 0 ? data.offerNumber : '' }} </template>
                    </Column>

                    <Column field="fixture.startDate" :header="$t('sportsbets.date')" :sortable="true">
                        <template #body="{ data }"> {{ formatCSDateAndTime(data.fixture.startDate) }} </template>
                    </Column>
                    <Column field="fixture.name" :header="$t('sportsSettings.name')" :sortable="true"> </Column>
                    <Column field="fixture.status" :header="$t('live.liveStatus')" :sortable="true">
                        <template #body="{ data }"> {{ decotLiveFixtureStatus(data.fixture.status) }} </template>
                    </Column>
                    <Column field="marketsNo" :header="$t('sportsSettings.marketsNo')" :sortable="true"> </Column>
                    <Column field="sportName" :header="$t('sportsbets.sport')" :sortable="true"></Column>
                    <Column field="locationName" :header="$t('sportsbets.location')" :sortable="true"></Column>

                    <Column field="leagueName" :header="$t('sportsbets.league')" :sortable="true"></Column>
                    <Column field="fixture.leagueId" :header="$t('live.leagueId')" :sortable="true"></Column>

                    <Column field="onTV" :header="$t('live.onTV')" :sortable="true">
                        <template #body="{ data }">
                            <div v-if="data.onTV == true">
                                <i class="pi pi-check" style="font-size: 1rem; color: #689f38; font-weight: bold"></i>
                            </div>
                            <div v-else>
                                <i class="pi pi-times" style="font-size: 1rem; color: #eb0909; font-weight: bold"></i>
                            </div>
                        </template>
                    </Column>
                    <Column>
                        <template #body="{ data }">
                            <Button :label="$t('buttons.showTickets')" class="p-button-text p-button-plain p-button-info p-mr-2 p-mb-2" @click="goto('sportBetsTickets', { backRoute: this.crtRoute, redirectFixtureId: data.fixtureId })" />
                        </template>
                    </Column>
                    <Column class="p-text-right">
                        <template #body="{ data }"> <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning p-mr-2" @click="goto('liveEventDetails', { id: data.fixtureId, backRoute: this.crtRoute })" /> </template
                    ></Column>
                    <template #expansion="slotProps">
                        <LiveEventOfferSettings
                            :inOffer="slotProps.data.inOffer"
                            :offerNumber="slotProps.data.offerNumber"
                            :fixtureId="slotProps.data.fixtureId"
                            @refresh="refresh"
                            @subscriptionError="subscriptionError"
                            :participant1="slotProps.data.participant1"
                            :participant2="slotProps.data.participant2"
                            :participant1Position="slotProps.data.participant1Position"
                            :participant2Position="slotProps.data.participant2Position"
                            :onTV="slotProps.data.onTV"
                            :tvSortNo="slotProps.data.tvSortNo"
                            :sportSortNo="slotProps.data.sportSortOrder"
                            :locationSortNo="slotProps.data.locationSortOrder"
                            :leagueSortNo="slotProps.data.leagueSortOrder"
                        ></LiveEventOfferSettings>
                    </template>
                </DataTable>
            </div>
        </div>
    </div>
</template>
<script>
import { LsportsApi } from '../../service/LsportsApi';
import { uiSettings } from '../../settings/uiSettings';

import LiveEventOfferSettings from './Components/LiveEventOfferSettings.vue';
export default {
    name: 'LiveOffer',
    components: { LiveEventOfferSettings },
    data() {
        return {
            tableHeight: uiSettings.tableHeight,
            items: [],
            crtPage: 1,
            LsportsApi: new LsportsApi(),
            startDateModel: null,
            endDateModel: null,
            perPage: uiSettings.tableRows,
            loading: true,
            sports: [],
            defaultSportsModel: { id: 0, name: 'All' },
            sportsModel: {},
            inOfferModel: { Name: 'All', Id: '0' },
            inOfferOptions: [
                { Name: 'All', Id: '0' },
                { Name: 'In Offer', Id: '1' },
                { Name: 'Out of Offer', Id: '2' },
            ],
            statusModel: { Name: 'All', Id: -1 },
            statusOptions: [
                { Name: 'All', Id: -1 },
                { Name: 'NotSet', Id: 0 },
                { Name: 'NSY', Id: 1 },
                { Name: 'InProgress', Id: 2 },
                { Name: 'Finished', Id: 3 },
                { Name: 'Cancelled', Id: 4 },
                { Name: 'Postponed', Id: 5 },
                { Name: 'Interrupted', Id: 6 },
                { Name: 'Abandoned', Id: 7 },
                { Name: 'LostCoverage', Id: 8 },
                { Name: 'AboutToStart', Id: 9 },
            ],
            showFetchBtn: false,
            filtersInitializationDone: false,
            showFixturesTable: false,
            showNoEvents: false,
            errorKeyCount: 0,
            expandedRows: [],
            crtRoute: 'liveOffer',
            offerNumber: '',
            searchName: '',
        };
    },
    computed: {
        startDateApi() {
            return this.formatApiDate(this.startDateModel);
        },
        endDateApi() {
            return this.formatApiDate(this.endDateModel);
        },
        filteredData() {
            if (this.statusModel.Id == -1) {
                return this.items;
            } else {
                var selectedStatus = this.statusModel.Id;
                return this.items.filter((item) => {
                    return item.fixture.status == selectedStatus;
                });
            }
        },
    },
    watch: {
        startDateModel() {
            this.showFixturesTable = false;
            this.sportsModel = { id: 0, name: 'All' };
            if (this.filtersInitializationDone) {
                this.getSports();
            }
        },
        endDateModel() {
            this.showFixturesTable = false;
            this.sportsModel = { id: 0, name: 'All' };
            if (this.filtersInitializationDone) {
                this.getSports();
            }
        },
        sportsModel() {
            if (this.filtersInitializationDone) {
                this.readItems();
            }
        },
        inOfferModel() {
            if (this.filtersInitializationDone) {
                this.readItems();
            }
        },
    },
    mounted() {
        let crtDate = new Date();
        let year = crtDate.getFullYear();
        let month = crtDate.getMonth() + 1;
        if (month <= 9) {
            month = '0' + month;
        }
        let day = crtDate.getDate();
        if (day <= 9) {
            day = '0' + day;
        }
        var startDateString = year + '-' + month + '-' + day + 'T00:00';

        const tomorrow = new Date(crtDate);
        tomorrow.setDate(tomorrow.getDate() + 1);
        let yearT = tomorrow.getFullYear();
        let monthT = tomorrow.getMonth() + 1;
        if (monthT <= 9) {
            monthT = '0' + monthT;
        }
        let dayT = tomorrow.getDate();
        if (dayT <= 9) {
            dayT = '0' + dayT;
        }
        var endDateString = yearT + '.' + monthT + '.' + dayT + ' 10:00';
        this.startDateModel = new Date(startDateString);
        this.endDateModel = new Date(endDateString);

        this.getSports();
    },
    methods: {
        readItems() {
            this.loading = true;
            this.showNoEvents = false;
            let searchOfferNumber = this.offerNumber;
            if (this.offerNumber == '') {
                searchOfferNumber = '0';
            }
            this.LsportsApi.getEvents(this.startDateApi, this.endDateApi, this.sportsModel.id, this.inOfferModel.Id, false, searchOfferNumber, this.searchName)
                .then((response) => {
                    this.items = response.data;
                    this.loading = false;
                    this.showFixturesTable = true;
                })
                .catch((error) => {
                    if (error.response.status == 404) {
                        this.showNoEvents = true;
                        this.showFixturesTable = false;
                        this.errorKeyCount++;
                    } else {
                        this.showToast('error', this.$t('alerts.generalError'));
                    }
                });
        },
        getSports() {
            this.showNoEvents = false;
            this.sports = [];
            this.LsportsApi.getSports(this.startDateApi, this.endDateApi, false)
                .then((response) => {
                    this.sports = response.data;
                    this.filtersInitializationDone = true;
                    this.sports.unshift(this.defaultSportsModel);
                    this.sportsModel = this.defaultSportsModel;
                })
                .catch((error) => {
                    if (error.response.status == 404) {
                        this.showNoEvents = true;
                        this.showFixturesTable = false;
                        this.errorKeyCount++;
                    } else {
                        this.showToast('error', this.$t('alerts.generalError'));
                    }
                });
        },
        showToast(toastSeverity, message) {
            this.$toast.add({ severity: toastSeverity, summary: '', detail: message, life: this.getToastLifeSeconds() });
        },
        clearFixturesTable() {
            this.items = [];
            this.showFixturesTable = false;
        },
        inOfferIcon(inOffer) {
            return inOffer == true ? 'check' : 'times';
        },
        inOfferColor(inOffer) {
            return inOffer == true ? 'success' : 'danger';
        },
        refresh() {
            this.expandedRows = [];
            this.readItems();
        },
        subscriptionError() {
            this.showToast('error', this.$t('alerts.liveSubscriptionError'));
        },
        tvError() {
            this.showToast('error', this.$t('alerts.liveTVError'));
        },
        onPage(event) {
            this.crtPage = event.page + 1;
        },
    },
};
</script>
