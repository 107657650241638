<template>
    <div class="p-col-12 p-md-10 p-md-offset-1 p-align-center p-card p-shadow-3">
        <Toast />
        <Fieldset :legend="$t('live.subscriptionSettings')" :toggleable="true" :collapsed="false" class="p-col-12 p-mb-2">
            <div class="p-grid p-align-start vertical-container">
                <div class="p-col-12 p-md-2">
                    <span class="p-float-label">
                        <InputText style="width: 100%" type="number" v-model="newOfferNumber" disabled />
                        <label>{{ $t('sportsbets.offerNo') }}</label>
                    </span>
                </div>
                <div class="p-col-12 p-md-2"><Button v-show="this.offerNumber == 0" :label="$t('buttons.generateOfferNumber')" class="p-button-text p-button-warning" @click="getLiveOfferNumber()" /></div>

                <div v-show="inOffer == false" class="p-col-12 p-md-8 p-text-right"><Button :label="$t('buttons.addInOffer')" class="p-button-raised p-button-success" @click="saveSubscriptionSettings(true)" /></div>
                <div v-show="inOffer == true" class="p-col-12 p-md-8 p-text-right"><Button :label="$t('buttons.removeFromOffer')" class="p-button-raised p-button-danger" @click="saveSubscriptionSettings(false)" /></div>
            </div>
            <div class="p-grid p-align-start vertical-container">
                <div class="p-col-12 p-md-2">
                    <span class="p-float-label">
                        <InputText style="width: 100%" type="number" v-model="newTvSortNo" />
                        <label>{{ $t('live.tvSortNo') }}</label>
                    </span>
                </div>
                <div class="p-col-12 p-md-2"><Button v-show="this.offerNumber == 0" :label="$t('buttons.updateSortNo')" class="p-button-text p-button-warning" @click="saveTVOfferSettings(onTV)" /></div>

                <div v-if="onTV == true" class="p-col-12 p-md-8 p-text-right"><Button :label="$t('buttons.removeFromTV')" class="p-button-raised p-button-danger" @click="saveTVOfferSettings(false)" /></div>
                <div v-else class="p-col-12 p-md-8 p-text-right"><Button :label="$t('buttons.addOnTV')" class="p-button-raised p-button-success" @click="saveTVOfferSettings(true)" /></div>
            </div>
        </Fieldset>
        <Fieldset :legend="$t('live.updateName')" :toggleable="true" :collapsed="true" class="p-col-12 p-mb-2">
            <div class="p-grid p-align-start vertical-container">
                <div class="p-col-12 p-md-2">
                    <span class="p-float-label">
                        <InputText style="width: 100%" type="text" v-model="participant1New" />
                        <label>{{ $t('sportsbets.homeTeam') }}</label>
                    </span>
                    <br />
                    <span class="p-float-label">
                        <InputText style="width: 100%" type="text" v-model="participant1PositionNew" />
                        <label>{{ $t('sportsbets.positionNo') }}</label>
                    </span>
                </div>
                <div class="p-col-12 p-md-2">
                    <span class="p-float-label" v-show="participant2New != ''">
                        <InputText style="width: 100%" type="text" v-model="participant2New" />
                        <label>{{ $t('sportsbets.awayTeam') }}</label>
                    </span>
                    <br />
                    <span class="p-float-label">
                        <InputText style="width: 100%" type="text" v-model="participant2PositionNew" />
                        <label>{{ $t('sportsbets.positionNo') }}</label>
                    </span>
                </div>
                <div class="p-col-12 p-md-8 p-text-right">
                    <Button :label="$t('buttons.save')" class="p-button-raised p-button-success" @click="saveNewParticipants()" />
                </div>
            </div>
        </Fieldset>
        <!--   <Fieldset :legend="$t('live.resultsSettings')" :toggleable="true" :collapsed="true" class="p-col-12 p-mb-2">
            <div class="p-grid p-align-start vertical-container">
                <div class="p-col-12 p-md-2">{{ settledBets + ' settled bets out of ' + betsNo + ' total bets' }}</div>
                <div class="p-col-12 p-md-2"><Button :label="$t('buttons.freeOfferNumber')" class="p-button-text p-button-success p-mt-0 p-pt-0" @click="freeLiveOfferNumber()" /></div>

                <div class="p-col-12 p-md-10 p-text-right"><Button :loading="resultsLoading" :label="$t('buttons.tryGetResults')" class="p-button-raised p-button-warning" @click="tryGetResults()" /></div>
            </div>
        </Fieldset>-->
    </div>
</template>
<script>
import { LsportsApi } from '../../../service/LsportsApi';
import { SportsSettingsApi } from '../../../service/SportSettingsApi';

export default {
    name: 'liveEventOfferSettings',
    emits: ['refresh', 'subscriptionError'],
    props: {
        inOffer: {
            type: Boolean,
        },
        onTV: {
            type: Boolean,
        },
        offerNumber: {
            type: Number,
        },
        tvSortNo: {
            type: Number,
        },
        sportSortNo: {
            type: Number,
        },
        leagueSortNo: {
            type: Number,
        },
        locationSortNo: {
            type: Number,
        },
        fixtureId: {
            type: Number,
        },
        participant1: {
            type: String,
        },
        participant2: {
            type: String,
        },
        participant1Position: {
            type: String,
        },
        participant2Position: {
            type: String,
        },
    },
    data() {
        return {
            LsportsApi: new LsportsApi(),
            SportsSettingsApi: new SportsSettingsApi(),
            newOfferNumber: 0,
            newTvSortNo: 0,
            resultsLoading: false,
            participant1New: '',
            participant2New: '',
            participant1PositionNew: '',
            participant2PositionNew: '',
        };
    },
    mounted() {
        this.newOfferNumber = this.offerNumber;
        if (this.tvSortNo != 0) {
            this.newTvSortNo = this.tvSortNo;
        } else {
            this.newTvSortNo = this.sportSortNo * 1000 + this.locationSortNo;
        }
        this.participant1New = this.participant1;
        this.participant2New = this.participant2;
        this.participant1PositionNew = this.participant1Position;
        this.participant2PositionNew = this.participant2Position;
    },
    methods: {
        showToast(toastSeverity, message) {
            this.$toast.add({ severity: toastSeverity, summary: '', detail: message, life: this.getToastLifeSeconds() });
        },
        saveSubscriptionSettings(inOfferFlag) {
            if (this.newOfferNumber <= 0 || this.newOfferNumber > 9999) {
                this.showToast('error', this.$t('live.alerts.invalidOfferNumber'));
            } else {
                var subscriptionRequest = {
                    InOffer: inOfferFlag,
                    OfferNumber: parseInt(this.newOfferNumber),
                    FixtureId: this.fixtureId,
                };
                this.LsportsApi.setFixtureSubscription(subscriptionRequest)
                    .then(() => {
                        this.$emit('refresh');
                    })
                    .catch((error) => {
                        this.checkForUnauthorizedError(error);
                        this.$emit('subscriptionError');
                    });
            }
        },
        saveTVOfferSettings(inOffer) {
            var subscriptionRequest = {
                TVOffer: inOffer,
                OfferNumber: parseInt(this.newOfferNumber),
                FixtureId: this.fixtureId,
                TVSortNo: parseInt(this.newTvSortNo),
            };
            this.LsportsApi.saveTVOfferSettings(subscriptionRequest)
                .then(() => {
                    this.$emit('refresh');
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.$emit('tvError');
                });
        },
        getLiveOfferNumber() {
            this.SportsSettingsApi.getLiveOfferNumber()
                .then((response) => {
                    this.newOfferNumber = parseInt(response.data);
                })
                .catch(() => {
                    this.showToast('error', this.$t('alerts.generalError'));
                });
        },
        freeLiveOfferNumber() {
            if (this.newOfferNumber != 0) {
                this.SportsSettingsApi.freeLiveOfferNumber(this.newOfferNumber)
                    .then(() => {
                        this.showToast('success', this.$t('live.alerts.freeOfferNumberSuccess'));
                    })
                    .catch(() => {
                        this.showToast('error', this.$t('alerts.generalError'));
                    });
            } else {
                this.showToast('error', this.$t('live.alerts.freeOfferNumberError'));
            }
        },
        tryGetResults() {
            this.resultsLoading = true;
            this.LsportsApi.reSendProviderResults(this.fixtureId)
                .then(() => {
                    this.LsportsApi.tryGetResults(this.fixtureId)
                        .then(() => {
                            this.resultsLoading = false;
                            this.$emit('refresh');
                        })
                        .catch(() => {
                            this.showToast('error', this.$t('alerts.generalError'));
                        });
                })
                .catch(() => {
                    this.showToast('error', this.$t('alerts.generalError'));
                });
        },
        saveNewParticipants() {
            this.LsportsApi.saveNewName(this.fixtureId, this.participant1New, this.participant2New, this.participant1PositionNew, this.participant2PositionNew)
                .then(() => {
                    this.showToast('success', this.$t('live.alerts.newNameSuccess'));
                })
                .catch(() => {
                    this.showToast('error', this.$t('live.alerts.newNameError'));
                });
        },
    },
};
</script>

